<template>
  <div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    save: Array,
    editID: [Number, String],
    onClose: Function
  },
  mounted () {
    this.getData()
    this.setSave()
  },
  computed: {

  },
  data () {
    return {
      edit: false,
      variables: this.$getUserVariables()
    }
  },
  methods: {
    getData () {
      this.save.forEach(function (v, k) {
        if (v.ID === this.editID) {
          this.edit = k
        }
      }.bind(this))
    },
    setSave () {
      var send = {
        user: this.variables.user.md5_id,
        ID: this.editID,
        data: this.save[this.edit].layout
      }
      this.$flix_post({
        url: 'pagebuilder/targetpage/save',
        data: send,
        callback: function (ret) { this.onClose(this.editID) }.bind(this)
      })
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
